import {Route, Routes, useNavigate, useLocation} from "react-router-dom"
import {useAuth} from "./hooks/use-auth"
import {AUTHENTICATION_STATUS} from "./constants/authStatus"
import LandingPage from "./components/LandingPage/LandingPage"
import SelectLocation from "./components/SelectLocation/SelectLocation"
import {PAGE_HEADER, RoutePaths} from "./constants"
import SelectAccountType from "./components/SelectAccountType"
import ValidateSpecialty from "./components/ValidateSpecialty"
import Veterinary from "./components/accounts/Veterinary"
import NonProfit from "./components/accounts/NonProfit"
import Military from "./components/accounts/Military"
import LawEnforcement from "./components/accounts/LawEnforcement"
import VetTech from "./components/accounts/VetTech"
import NewShipTo from "./components/shipto/qualifier"
import AddShipTo from "./components/shipto/add"
import AdditionShipTo from "./components/shipto/additional"
import {useContext, useEffect} from "react"
import {nacMachineContext} from "./context"
import {NACEvents, NACStates} from "./machine"
import SelectTranscationType from "./components/SelectTranscationType"
import FinancialInformation from "./components/FinancialInformation"
import BusinessInformation from "./components/BusinessInformation"
import PaymentInformation from "./components/PaymentInformation"
import Contacts from "./components/contacts"
import DateSign from "./components/DateSign"
import Review from "./components/review"
import ThankYou from "./components/ThankYou"
import HowManyShipTo from "./components/shipto/howMany"
import ShipToAccount from "./components/shipto/account"
import AdditionalShipTo from "./components/shipto/transfer"
import UpdateAccount from "./components/UpdateAccount"
import Sign from "./components/Sign"
import NotAuthenticated from "./components/ErrorPage/NotAuthenticated"
import {AccountType} from "./constants/index"
import AllowPet from "./components/accounts/Veterinary/allowPet"
import StoreLocation from "./components/accounts/Veterinary/storeLocation"
import { FormAbandonmentTracker, ScrollDepthTracker } from "./utils/trackEvents"

const App = () => {
  const {state, send} = useContext(nacMachineContext)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  const auth: any = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  window.onpopstate = function () {
    send(NACEvents.BACK, {
      data: {history: state?.history?.value},
    })
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    "event": "sign_up",
    "action": "step",
    "user_type" : getAccountType(Number(state?.context?.accountType)),
    "method": "Gigya",
    "sign_up_step": state.value,
    "click_text": "Continue",
    "click_url": location.pathname
  })

  function getAccountType(number: number): string {
    const accountTypes = Object.keys(AccountType)
      .filter(key => isNaN(Number(key)));

    return accountTypes[number];
  }

  useEffect(() => {
    // On refresh redirect to start page
    if (state?.matches(NACStates.init)) {
      // navigate(RoutePaths.LANDING_PAGE)
    }
  }, [state, navigate])
  return (
    <>
      {auth.status == AUTHENTICATION_STATUS.NOT_AUTHENTICATED ? (
        <NotAuthenticated />
      ) : null}
      {auth.status == AUTHENTICATION_STATUS.AUTHENTICATED ? (
        <>
          <header className="flex flex-col items-center justify-center">
            {location.pathname !== RoutePaths.THANK_YOU && (
              <h1 className="my-6 max-w-md text-center text-[2.75rem] font-extrabold leading-10">
                {PAGE_HEADER}
              </h1>
            )}
          </header>

          <main className="flex flex-col items-center justify-center">
            <ScrollDepthTracker />
            <FormAbandonmentTracker />
            <Routes>
              <Route
                path={RoutePaths.SELECT_LOCATION}
                element={<SelectLocation />}
              />
              <Route path={RoutePaths.LANDING_PAGE} element={<LandingPage />} />
              <Route
                path={RoutePaths.ACCOUNT_TYPE}
                element={<SelectAccountType />}
              />
              <Route path={RoutePaths.ACCOUNT_TYPE}>
                <Route index element={<SelectAccountType />} />
                <Route
                  path={RoutePaths.ACCOUNT_VETERINARY}
                  element={<Veterinary />}
                />
                <Route path={RoutePaths.ACCOUNT_PET} element={<Veterinary />} />
                <Route path={RoutePaths.ACCOUNT_PET_ALLOWED} element={<AllowPet />} />
                <Route path={RoutePaths.ACCOUNT_STORE_LOCATION} element={<StoreLocation />} />
                <Route
                  path={RoutePaths.ACCOUNT_NON_PROFIT}
                  element={<NonProfit />}
                />
                <Route
                  path={RoutePaths.ACCOUNT_MILITARY}
                  element={<Military />}
                />
                <Route
                  path={RoutePaths.ACCOUNT_LAW_ENFORCEMENT}
                  element={<LawEnforcement />}
                />
                <Route
                  path={RoutePaths.ACCOUNT_VET_TECH}
                  element={<VetTech />}
                />
              </Route>
              <Route
                path={RoutePaths.SELECT_TRANSACTION_TYPE}
                element={<SelectTranscationType />}
              />
              <Route
                path={RoutePaths.ACCOUNT_VETERINARY}
                element={<Veterinary />}
              />
              <Route
                path={RoutePaths.ACCOUNT_NON_PROFIT}
                element={<NonProfit />}
              />
              <Route
                path={RoutePaths.BUISNESS_INFORMATION}
                element={<BusinessInformation />}
              />
              <Route
                path={RoutePaths.ACCOUNT_MILITARY}
                element={<Military />}
              />
              <Route
                path={RoutePaths.ACCOUNT_LAW_ENFORCEMENT}
                element={<LawEnforcement />}
              />
              <Route
                path={RoutePaths.VALIDATE_SPECIALTY}
                element={<ValidateSpecialty />}
              />
              <Route
                path={RoutePaths.FINANCIAL_INFO}
                element={<FinancialInformation />}
              />
              <Route
                path={RoutePaths.SHIP_TO_QUALIFIER}
                element={<NewShipTo />}
              />
              <Route path={RoutePaths.ADD_SHIPTO} element={<AddShipTo />} />
              <Route
                path={RoutePaths.ADDITIONAL_SHIPTO}
                element={<AdditionShipTo />}
              />
              <Route
                path={RoutePaths.PAYMENT_INFORMATION}
                element={<PaymentInformation />}
              />
              <Route path={RoutePaths.CONTACTS} element={<Contacts />} />
              <Route path={RoutePaths.DATE_SIGN} element={<DateSign />} />
              <Route path={RoutePaths.REVIEW} element={<Review />} />
              <Route path={RoutePaths.THANK_YOU} element={<ThankYou />} />
              <Route
                path={RoutePaths.HOW_MANY_SHIP_TO_QUESTION}
                element={<HowManyShipTo />}
              />
              <Route
                path={RoutePaths.SHIP_TO_ACCOUNT}
                element={<ShipToAccount />}
              />
              <Route
                path={RoutePaths.ADDITIONAL_SHIPTO_TRANSFER}
                element={<AdditionalShipTo />}
              />
              <Route
                path={RoutePaths.UPDATE_ACCOUNT}
                element={<UpdateAccount />}
              />
              <Route path={RoutePaths.SIGN} element={<Sign />} />
            </Routes>
          </main>
        </>
      ) : null}
    </>
  )
}

export default App
