import {
  CalendarIcon_default,
  CheckmarkIcon_default,
  CloseIcon_default,
  CrossIcon_default,
  EditIcon_default,
  ExpandedDownIcon_default,
  ExpandedUpIcon_default,
  IconEye_default,
  IconInformationCircleFilled_default,
  IconPdf_default,
  IconPersonOutline_default,
  IconTrash_default,
  IconUpload_default,
  IconWarning_default,
  PlusIcon_default,
  TrashIcon_default,
  __objRest,
  __spreadProps,
  __spreadValues
} from "./chunk-JERET5K2.mjs";

// src/components/Button/Button.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var variantClasses = {
  primary: {
    filled: "bg-brand-primary border border-brand-primary text-white hover:opacity-90",
    outlined: "text-brand-primary border border-brand-primary hover:opacity-90"
  },
  danger: {
    filled: "bg-red-500 border border-red-500 text-white hover:opacity-90",
    outlined: "text-red-500 border border-red-500 hover:opacity-90"
  },
  text: {
    filled: "border-spacing-0 text-brand-primary p-0 bg-inherit hover:bg-white",
    outlined: "border-spacing-0 text-brand-primary p-0 bg-inherit hover:bg-white"
  },
  default: {
    filled: "bg-gray-500 hover:bg-gray-100",
    outlined: "border border-gray-400 hover:bg-gray-100"
  }
};
var sizeClasses = {
  sm: "font-medium",
  md: "py-2 font-bold",
  lg: "py-4 font-bold"
};
var Button = (_a) => {
  var _b = _a, {
    variant = "primary",
    children = "Submit",
    size = "md",
    classes = "",
    mode = "outlined",
    type = "button",
    icon
  } = _b, rest = __objRest(_b, [
    "variant",
    "children",
    "size",
    "classes",
    "mode",
    "type",
    "icon"
  ]);
  return /* @__PURE__ */ jsxs("button", __spreadProps(__spreadValues({
    type
  }, rest), {
    className: `rounded px-2 focus:outline-dashed focus:outline-2 focus:outline-offset-2 
      focus:outline-brand-primary disabled:opacity-50 md:px-4 ${variantClasses[variant][mode]} 
      flex active:outline-none
      ${sizeClasses[size]} ${classes}`,
    children: [
      /* @__PURE__ */ jsx("div", {
        className: "mt-1",
        children: icon
      }),
      children
    ]
  }));
};
Button.displayName = "Button";

// src/components/CheckMark/CheckMark.tsx
import { jsx as jsx2 } from "react/jsx-runtime";
var CheckMark = ({ color, variant }) => {
  const isPrimary = variant === "primary";
  return /* @__PURE__ */ jsx2("div", {
    className: `${isPrimary ? "h-5 w-5 rounded-full bg-brand-primary text-white" : ""}`,
    children: /* @__PURE__ */ jsx2(CheckmarkIcon_default, {
      titleId: "checkMarkIcon",
      title: "checkMarkIcon"
    })
  });
};
CheckMark.displayName = "CheckMark";

// src/components/FormControls/FormGroup.tsx
import { jsx as jsx3 } from "react/jsx-runtime";
function FormGroup({ children }) {
  return /* @__PURE__ */ jsx3("div", {
    className: "mb-2.5 flex flex-wrap gap-2.5",
    children
  });
}

// ../../node_modules/@babel/runtime/helpers/esm/extends.js
function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}

// ../../node_modules/@babel/runtime/helpers/esm/objectWithoutPropertiesLoose.js
function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null)
    return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0)
      continue;
    target[key] = source[key];
  }
  return target;
}

// ../../node_modules/react-final-form/dist/react-final-form.es.js
import * as React from "react";
import React__default from "react";

// ../../node_modules/final-form/dist/final-form.es.js
var charCodeOfDot = ".".charCodeAt(0);
var rePropName = RegExp(
  `[^.[\\]]+|\\[(?:([^"'][^[]*)|(["'])((?:(?!\\2)[^\\\\]|\\\\.)*?)\\2)\\]|(?=(?:\\.|\\[\\])(?:\\.|\\[\\]|$))`,
  "g"
);
var fieldSubscriptionItems = ["active", "data", "dirty", "dirtySinceLastSubmit", "error", "initial", "invalid", "length", "modified", "modifiedSinceLastSubmit", "pristine", "submitError", "submitFailed", "submitSucceeded", "submitting", "touched", "valid", "value", "visited", "validating"];
var formSubscriptionItems = ["active", "dirty", "dirtyFields", "dirtyFieldsSinceLastSubmit", "dirtySinceLastSubmit", "error", "errors", "hasSubmitErrors", "hasValidationErrors", "initialValues", "invalid", "modified", "modifiedSinceLastSubmit", "pristine", "submitting", "submitError", "submitErrors", "submitFailed", "submitSucceeded", "touched", "valid", "validating", "values", "visited"];

// ../../node_modules/react-final-form/dist/react-final-form.es.js
var _excluded$3 = ["render", "children", "component"];
function renderComponent(props, lazyProps, name) {
  var render = props.render, children = props.children, component = props.component, rest = _objectWithoutPropertiesLoose(props, _excluded$3);
  if (component) {
    return /* @__PURE__ */ React.createElement(component, Object.assign(lazyProps, rest, {
      children,
      render
    }));
  }
  if (render) {
    return render(children === void 0 ? Object.assign(lazyProps, rest) : Object.assign(lazyProps, rest, {
      children
    }));
  }
  if (typeof children !== "function") {
    throw new Error("Must specify either a render prop, a render function as children, or a component prop to " + name);
  }
  return children(Object.assign(lazyProps, rest));
}
var ReactFinalFormContext = /* @__PURE__ */ React.createContext();
function useLatest(value) {
  var ref = React__default.useRef(value);
  React__default.useEffect(function() {
    ref.current = value;
  });
  return ref;
}
var addLazyState = function addLazyState2(dest, state, keys) {
  keys.forEach(function(key) {
    Object.defineProperty(dest, key, {
      get: function get() {
        return state[key];
      },
      enumerable: true
    });
  });
};
var addLazyFieldMetaState = function addLazyFieldMetaState2(dest, state) {
  return addLazyState(dest, state, ["active", "data", "dirty", "dirtySinceLastSubmit", "error", "initial", "invalid", "length", "modified", "modifiedSinceLastSubmit", "pristine", "submitError", "submitFailed", "submitSucceeded", "submitting", "touched", "valid", "validating", "visited"]);
};
var all$1 = formSubscriptionItems.reduce(function(result, key) {
  result[key] = true;
  return result;
}, {});
function useForm(componentName) {
  var form = React.useContext(ReactFinalFormContext);
  if (!form) {
    throw new Error((componentName || "useForm") + " must be used inside of a <Form> component");
  }
  return form;
}
var isReactNative = typeof window !== "undefined" && window.navigator && window.navigator.product && window.navigator.product === "ReactNative";
var getSelectedValues = function getSelectedValues2(options) {
  var result = [];
  if (options) {
    for (var index = 0; index < options.length; index++) {
      var option = options[index];
      if (option.selected) {
        result.push(option.value);
      }
    }
  }
  return result;
};
var getValue = function getValue2(event, currentValue, valueProp, isReactNative2) {
  if (!isReactNative2 && event.nativeEvent && event.nativeEvent.text !== void 0) {
    return event.nativeEvent.text;
  }
  if (isReactNative2 && event.nativeEvent) {
    return event.nativeEvent.text;
  }
  var detypedEvent = event;
  var _detypedEvent$target = detypedEvent.target, type = _detypedEvent$target.type, value = _detypedEvent$target.value, checked = _detypedEvent$target.checked;
  switch (type) {
    case "checkbox":
      if (valueProp !== void 0) {
        if (checked) {
          return Array.isArray(currentValue) ? currentValue.concat(valueProp) : [valueProp];
        } else {
          if (!Array.isArray(currentValue)) {
            return currentValue;
          }
          var index = currentValue.indexOf(valueProp);
          if (index < 0) {
            return currentValue;
          } else {
            return currentValue.slice(0, index).concat(currentValue.slice(index + 1));
          }
        }
      } else {
        return !!checked;
      }
    case "select-multiple":
      return getSelectedValues(event.target.options);
    default:
      return value;
  }
};
function useConstantCallback(callback) {
  var ref = React.useRef(callback);
  React.useEffect(function() {
    ref.current = callback;
  });
  return React.useCallback(function() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    return ref.current.apply(null, args);
  }, []);
}
var all = fieldSubscriptionItems.reduce(function(result, key) {
  result[key] = true;
  return result;
}, {});
var defaultFormat = function defaultFormat2(value, name) {
  return value === void 0 ? "" : value;
};
var defaultParse = function defaultParse2(value, name) {
  return value === "" ? void 0 : value;
};
var defaultIsEqual = function defaultIsEqual2(a, b) {
  return a === b;
};
function useField(name, config) {
  if (config === void 0) {
    config = {};
  }
  var _config = config, afterSubmit = _config.afterSubmit, allowNull = _config.allowNull, component = _config.component, data = _config.data, defaultValue = _config.defaultValue, _config$format = _config.format, format = _config$format === void 0 ? defaultFormat : _config$format, formatOnBlur = _config.formatOnBlur, initialValue = _config.initialValue, multiple = _config.multiple, _config$parse = _config.parse, parse = _config$parse === void 0 ? defaultParse : _config$parse, _config$subscription = _config.subscription, subscription = _config$subscription === void 0 ? all : _config$subscription, type = _config.type, validateFields = _config.validateFields, _value = _config.value;
  var form = useForm("useField");
  var configRef = useLatest(config);
  var register = function register2(callback, silent) {
    return form.registerField(name, callback, subscription, {
      afterSubmit,
      beforeSubmit: function beforeSubmit() {
        var _configRef$current = configRef.current, beforeSubmit2 = _configRef$current.beforeSubmit, formatOnBlur2 = _configRef$current.formatOnBlur, _configRef$current$fo = _configRef$current.format, format2 = _configRef$current$fo === void 0 ? defaultFormat : _configRef$current$fo;
        if (formatOnBlur2) {
          var _ref = form.getFieldState(name), value = _ref.value;
          var formatted = format2(value, name);
          if (formatted !== value) {
            form.change(name, formatted);
          }
        }
        return beforeSubmit2 && beforeSubmit2();
      },
      data,
      defaultValue,
      getValidator: function getValidator() {
        return configRef.current.validate;
      },
      initialValue,
      isEqual: function isEqual(a, b) {
        return (configRef.current.isEqual || defaultIsEqual)(a, b);
      },
      silent,
      validateFields
    });
  };
  var firstRender = React.useRef(true);
  var _React$useState = React.useState(function() {
    var initialState = {};
    var destroyOnUnregister = form.destroyOnUnregister;
    form.destroyOnUnregister = false;
    register(function(state2) {
      initialState = state2;
    }, true)();
    form.destroyOnUnregister = destroyOnUnregister;
    return initialState;
  }), state = _React$useState[0], setState = _React$useState[1];
  React.useEffect(
    function() {
      return register(function(state2) {
        if (firstRender.current) {
          firstRender.current = false;
        } else {
          setState(state2);
        }
      }, false);
    },
    [
      name,
      data,
      defaultValue,
      initialValue
    ]
  );
  var meta = {};
  addLazyFieldMetaState(meta, state);
  var input = {
    name,
    get value() {
      var value = state.value;
      if (formatOnBlur) {
        if (component === "input") {
          value = defaultFormat(value);
        }
      } else {
        value = format(value, name);
      }
      if (value === null && !allowNull) {
        value = "";
      }
      if (type === "checkbox" || type === "radio") {
        return _value;
      } else if (component === "select" && multiple) {
        return value || [];
      }
      return value;
    },
    get checked() {
      var value = state.value;
      if (type === "checkbox") {
        value = format(value, name);
        if (_value === void 0) {
          return !!value;
        } else {
          return !!(Array.isArray(value) && ~value.indexOf(_value));
        }
      } else if (type === "radio") {
        return format(value, name) === _value;
      }
      return void 0;
    },
    onBlur: useConstantCallback(function(event) {
      state.blur();
      if (formatOnBlur) {
        var fieldState = form.getFieldState(state.name);
        state.change(format(fieldState.value, state.name));
      }
    }),
    onChange: useConstantCallback(function(event) {
      if (process.env.NODE_ENV !== "production" && event && event.target) {
        var targetType = event.target.type;
        var unknown = ~["checkbox", "radio", "select-multiple"].indexOf(targetType) && !type && component !== "select";
        var _value2 = targetType === "select-multiple" ? state.value : _value;
        if (unknown) {
          console.error('You must pass `type="' + (targetType === "select-multiple" ? "select" : targetType) + '"` prop to your Field(' + name + ") component.\n" + ("Without it we don't know how to unpack your `value` prop - " + (Array.isArray(_value2) ? "[" + _value2 + "]" : '"' + _value2 + '"') + "."));
        }
      }
      var value = event && event.target ? getValue(event, state.value, _value, isReactNative) : event;
      state.change(parse(value, name));
    }),
    onFocus: useConstantCallback(function(event) {
      return state.focus();
    })
  };
  if (multiple) {
    input.multiple = multiple;
  }
  if (type !== void 0) {
    input.type = type;
  }
  var renderProps = {
    input,
    meta
  };
  return renderProps;
}
var _excluded = ["afterSubmit", "allowNull", "beforeSubmit", "children", "component", "data", "defaultValue", "format", "formatOnBlur", "initialValue", "isEqual", "multiple", "name", "parse", "subscription", "type", "validate", "validateFields", "value"];
var Field = /* @__PURE__ */ React.forwardRef(function Field2(_ref, ref) {
  var afterSubmit = _ref.afterSubmit, allowNull = _ref.allowNull, beforeSubmit = _ref.beforeSubmit, children = _ref.children, component = _ref.component, data = _ref.data, defaultValue = _ref.defaultValue, format = _ref.format, formatOnBlur = _ref.formatOnBlur, initialValue = _ref.initialValue, isEqual = _ref.isEqual, multiple = _ref.multiple, name = _ref.name, parse = _ref.parse, subscription = _ref.subscription, type = _ref.type, validate = _ref.validate, validateFields = _ref.validateFields, value = _ref.value, rest = _objectWithoutPropertiesLoose(_ref, _excluded);
  var field = useField(name, {
    afterSubmit,
    allowNull,
    beforeSubmit,
    children,
    component,
    data,
    defaultValue,
    format,
    formatOnBlur,
    initialValue,
    isEqual,
    multiple,
    parse,
    subscription,
    type,
    validate,
    validateFields,
    value
  });
  if (typeof children === "function") {
    return children(_extends({}, field, rest));
  }
  if (typeof component === "string") {
    return /* @__PURE__ */ React.createElement(component, _extends({}, field.input, {
      children,
      ref
    }, rest));
  }
  if (!name) {
    throw new Error("prop name cannot be undefined in <Field> component");
  }
  return renderComponent(_extends({
    children,
    component,
    ref
  }, rest), field, "Field(" + name + ")");
});

// src/components/FormControls/FormControl.tsx
import { Fragment, jsx as jsx4, jsxs as jsxs2 } from "react/jsx-runtime";
var getFormControlType = (type, input, children) => {
  switch (type) {
    case "input":
      return /* @__PURE__ */ jsx4("input", __spreadProps(__spreadValues({}, input), {
        className: "rounded-md border bg-white px-3 py-2 placeholder-slate-400  focus:outline-none  disabled:opacity-50 sm:text-sm"
      }));
    case "select":
      return /* @__PURE__ */ jsx4("select", __spreadProps(__spreadValues({}, input), {
        className: "rounded-md border bg-white px-3 py-2 focus:outline-none disabled:opacity-50",
        children
      }));
    case "textarea":
      return /* @__PURE__ */ jsx4("textarea", __spreadProps(__spreadValues({}, input), {
        children
      }));
    default:
      return /* @__PURE__ */ jsx4(Fragment, {
        children: type
      });
  }
};
var FormControl = (_a) => {
  var _b = _a, {
    label,
    children,
    placeholder,
    classes = "",
    disabled = false,
    type = "text",
    onBlurCapture,
    max = "",
    fieldProps,
    flexDirection = "col"
  } = _b, rest = __objRest(_b, [
    "label",
    "children",
    "placeholder",
    "classes",
    "disabled",
    "type",
    "onBlurCapture",
    "max",
    "fieldProps",
    "flexDirection"
  ]);
  return /* @__PURE__ */ jsxs2("div", {
    className: `flex flex-1 flex-${flexDirection} gap-1 ${classes}`,
    children: [
      /* @__PURE__ */ jsx4("label", {
        className: "mb-1 self-baseline",
        htmlFor: fieldProps.name,
        children: label
      }),
      /* @__PURE__ */ jsx4(Field, __spreadProps(__spreadValues({}, fieldProps), {
        type,
        children: ({ input, meta }) => {
          return /* @__PURE__ */ jsxs2(Fragment, {
            children: [
              getFormControlType(
                fieldProps.component,
                __spreadValues(__spreadProps(__spreadValues({}, input), {
                  placeholder,
                  disabled,
                  max,
                  id: input.name,
                  onBlurCapture
                }), rest),
                children
              ),
              meta.error && meta.touched && /* @__PURE__ */ jsxs2("span", {
                className: "error text-sm text-red-600",
                children: [
                  label,
                  " ",
                  meta.error
                ]
              })
            ]
          });
        }
      }))
    ]
  });
};
FormControl.displayName = "FormControl";

// src/components/FormControls/validations.tsx
var required = (value) => value ? void 0 : "is required";
var mustBeNumber = (value) => value && isNaN(value) ? "must be a number" : void 0;
var minValue = (min) => (value) => value && isNaN(value) || value >= min ? void 0 : `should be greater than ${min}`;
var equalValue = (equalTo) => (value) => value && value.length !== equalTo ? `should be ${equalTo} ${isNaN(value) ? "characters" : "digits"} long.` : void 0;
var minLength = (equalTo) => (value) => value && value.length < equalTo ? `can only be ${equalTo} ${isNaN(value) ? "characters" : "digits"} long.` : void 0;
var maxLength = (equalTo) => (value) => value && value.length > equalTo ? `can only be ${equalTo} ${isNaN(value) ? "characters" : "digits"} long.` : void 0;
var emojiValidation = (value) => (value == null ? void 0 : value.toString().match(new RegExp("\\p{Extended_Pictographic}", "gu"))) ? "is invalid" : void 0;
var onlyCharValidation = (value) => (value == null ? void 0 : value.toString().match(/^[A-Za-z ]+$/)) ? void 0 : "can't contain number and special chars";
var composeValidators = (...validators) => (value) => validators.reduce(
  (error, validator) => error || validator(value),
  void 0
);

// src/components/TextLink/TextLink.tsx
import { jsx as jsx5 } from "react/jsx-runtime";
var TextLink = ({
  variant = "secondary",
  href = "#",
  title
}) => {
  return /* @__PURE__ */ jsx5("a", {
    className: `inline-block rounded-full px-3 py-2${variant === "primary" ? "text-base font-normal text-brand-primary hover:underline" : "text-base font-bold text-brand-primary hover:underline"}`,
    href,
    children: title
  });
};
TextLink.displayName = "TextLink";

// src/components/WrapperContainer/WrapperContainer.tsx
import { jsx as jsx6 } from "react/jsx-runtime";
var WrapperContainer = ({
  children,
  classes = ""
}) => {
  return /* @__PURE__ */ jsx6("main", {
    className: `bg-brand-secondary py-5 px-4 md:px-8 md:py-8 ${classes}`,
    children
  });
};
WrapperContainer.displayName = "WrapperContainer";

// src/components/CheckMarkIconWithLabel/CheckMarkIconWithLabel.tsx
import { jsx as jsx7, jsxs as jsxs3 } from "react/jsx-runtime";
var CheckMarckIconWithLabel = ({
  title,
  description,
  href
}) => {
  return /* @__PURE__ */ jsxs3("div", {
    className: "flex items-center gap-4",
    "data-testid": "check-mark",
    children: [
      /* @__PURE__ */ jsx7("div", {
        className: "w-5",
        children: /* @__PURE__ */ jsx7(CheckMark, {
          variant: "primary",
          color: "#fff"
        })
      }),
      /* @__PURE__ */ jsxs3("div", {
        className: "flex flex-col gap-1",
        children: [
          href ? /* @__PURE__ */ jsx7(TextLink, {
            href,
            title
          }) : /* @__PURE__ */ jsx7("p", {
            className: "mt-5px max-w-lg text-[12px] font-bold text-black",
            children: title
          }),
          description && /* @__PURE__ */ jsx7("p", {
            className: "max-w-xs text-[10px] text-brand-accent-gray-light md:max-w-sm",
            children: description
          })
        ]
      })
    ]
  });
};
CheckMarckIconWithLabel.displayName = "CheckMarckIconWithLabel";

// src/components/Radio/Radio.tsx
import { jsx as jsx8, jsxs as jsxs4 } from "react/jsx-runtime";
function Radio(_a) {
  var _b = _a, {
    input,
    meta,
    label,
    helpertext,
    helperclass = ""
  } = _b, rest = __objRest(_b, [
    "input",
    "meta",
    "label",
    "helpertext",
    "helperclass"
  ]);
  return /* @__PURE__ */ jsxs4("label", {
    className: "relative inline-flex cursor-pointer items-center gap-5 p-3 font-medium text-gray-500 hover:rounded-md hover:bg-white hover:shadow-[2px_2px_10px_rgba(0,0,0,0.3)] active:bg-brand-primary/[0.12] active:shadow-none",
    children: [
      /* @__PURE__ */ jsx8("input", __spreadProps(__spreadValues(__spreadValues({
        type: "radio"
      }, input), rest), {
        className: "h-4 w-4 shrink-0 cursor-pointer appearance-none rounded-full border-2 border-brand-primary checked:bg-brand-primary"
      })),
      /* @__PURE__ */ jsxs4("p", {
        className: "cursor-pointer",
        children: [
          label,
          /* @__PURE__ */ jsx8("span", {
            className: `block text-xs text-gray-500 ${helperclass}`,
            children: helpertext
          })
        ]
      })
    ]
  });
}
Radio.displayName = "Radio";

// src/components/AlertDialog/AlertDialog.tsx
import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";
import { jsx as jsx9, jsxs as jsxs5 } from "react/jsx-runtime";
var AlertDialog = ({
  triggerElement,
  title,
  description,
  labelClose = "Close",
  labelConfirm,
  handleConfirm,
  open,
  setOpen,
  children,
  size = "sm",
  disableClose = false,
  disableSubmit = false,
  titleHelper,
  confirmVariant = "primary"
}) => {
  const setMaxWidth = () => {
    switch (size) {
      case "sm":
        return "max-w-[400px]";
      case "lg":
        return "max-w-[800px]";
      default:
        return "max-w-[400px]";
    }
  };
  return /* @__PURE__ */ jsxs5(AlertDialogPrimitive.Root, {
    open,
    onOpenChange: setOpen,
    children: [
      triggerElement && /* @__PURE__ */ jsx9(AlertDialogPrimitive.Trigger, {
        asChild: true,
        children: triggerElement
      }),
      /* @__PURE__ */ jsxs5(AlertDialogPrimitive.Portal, {
        children: [
          /* @__PURE__ */ jsx9(AlertDialogPrimitive.Overlay, {
            className: "fixed inset-0 z-20 bg-gray-200 opacity-50"
          }),
          /* @__PURE__ */ jsxs5(AlertDialogPrimitive.Content, {
            className: `fixed top-1/2 left-1/2 z-30 max-h-[85vh] w-[90vw] -translate-x-1/2 -translate-y-1/2  overflow-scroll bg-white p-10 shadow-lg ${setMaxWidth()}`,
            children: [
              !disableClose && /* @__PURE__ */ jsx9(AlertDialogPrimitive.Cancel, {
                asChild: true,
                children: /* @__PURE__ */ jsx9("div", {
                  className: "absolute top-6 right-6 h-3 w-3 cursor-pointer",
                  children: /* @__PURE__ */ jsx9(CloseIcon_default, {})
                })
              }),
              /* @__PURE__ */ jsx9(AlertDialogPrimitive.Title, {
                asChild: true,
                children: /* @__PURE__ */ jsxs5("h2", {
                  className: "m-0 text-lg text-gray-900",
                  children: [
                    /* @__PURE__ */ jsx9("span", {
                      className: "font-bold ",
                      children: title
                    }),
                    " ",
                    titleHelper && /* @__PURE__ */ jsx9("span", {
                      className: "font-normal",
                      children: titleHelper
                    })
                  ]
                })
              }),
              description && /* @__PURE__ */ jsx9(AlertDialogPrimitive.Description, {
                className: "mb-9 mt-4 font-light leading-6 text-gray-500",
                dangerouslySetInnerHTML: { __html: description }
              }),
              children,
              /* @__PURE__ */ jsxs5("div", {
                className: "flex justify-end gap-8",
                children: [
                  !disableClose && /* @__PURE__ */ jsx9(AlertDialogPrimitive.Cancel, {
                    asChild: true,
                    children: /* @__PURE__ */ jsx9("button", {
                      className: "text-lg font-semibold text-brand-primary",
                      children: labelClose
                    })
                  }),
                  labelConfirm && /* @__PURE__ */ jsx9(Button, {
                    disabled: disableSubmit,
                    onClick: handleConfirm,
                    variant: confirmVariant,
                    mode: "filled",
                    children: labelConfirm
                  })
                ]
              })
            ]
          })
        ]
      })
    ]
  });
};
AlertDialog.displayName = "AlertDialog";

// src/components/Input/Input.tsx
import { jsx as jsx10, jsxs as jsxs6 } from "react/jsx-runtime";
function Input(_a) {
  var _b = _a, {
    input,
    type,
    meta,
    id,
    label,
    htmlFor,
    helpertext,
    disabled
  } = _b, rest = __objRest(_b, [
    "input",
    "type",
    "meta",
    "id",
    "label",
    "htmlFor",
    "helpertext",
    "disabled"
  ]);
  const renderHelperText = () => {
    if (meta.error && meta.touched) {
      return /* @__PURE__ */ jsx10("div", {
        className: "pl-3 pt-1 text-xs text-red-700",
        children: meta.error
      });
    }
    if (helpertext) {
      return /* @__PURE__ */ jsx10("div", {
        className: "pl-3 pt-1 text-xs text-slate-600",
        children: helpertext
      });
    }
    return null;
  };
  return /* @__PURE__ */ jsxs6("div", {
    className: "mb-1",
    children: [
      /* @__PURE__ */ jsxs6("div", {
        className: "relative",
        children: [
          input.type === "date" && /* @__PURE__ */ jsx10("span", {
            className: "absolute top-5 right-5 h-5 w-5 cursor-pointer",
            children: /* @__PURE__ */ jsx10(CalendarIcon_default, {})
          }),
          /* @__PURE__ */ jsx10("input", __spreadProps(__spreadValues(__spreadProps(__spreadValues({}, input), {
            disabled
          }), rest), {
            id: htmlFor,
            className: `peer block w-full appearance-none rounded-md border ${disabled ? "cursor-not-allowed bg-slate-100" : "bg-white"}
          ${meta.error && meta.touched ? `border-red-700` : `border-zinc-300`}  px-3 pt-5 pb-1 text-lg text-brand-accent-gray-light focus:text-brand-accent-default focus:outline-none focus:ring-0`,
            placeholder: " "
          })),
          /* @__PURE__ */ jsx10("label", {
            htmlFor,
            className: `${meta.error && meta.touched && input.value !== "" ? `text-red-700` : `text-brand-accent-gray-light`} absolute top-4 left-3 z-10 origin-[0] -translate-y-4 scale-75  transform  duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-brand-accent-default`,
            children: label
          })
        ]
      }),
      renderHelperText()
    ]
  });
}
Input.displayName = "Input";

// src/components/TextArea/TextArea.tsx
import { jsx as jsx11, jsxs as jsxs7 } from "react/jsx-runtime";
function TextArea(_a) {
  var _b = _a, {
    input,
    type,
    meta,
    id,
    label,
    htmlFor,
    helpertext
  } = _b, rest = __objRest(_b, [
    "input",
    "type",
    "meta",
    "id",
    "label",
    "htmlFor",
    "helpertext"
  ]);
  return /* @__PURE__ */ jsxs7("div", {
    className: "mb-4 mt-3",
    children: [
      /* @__PURE__ */ jsx11("div", {
        className: "relative",
        children: /* @__PURE__ */ jsx11("textarea", __spreadProps(__spreadValues(__spreadValues({}, input), rest), {
          id: htmlFor,
          rows: 3,
          className: `peer block w-full appearance-none rounded-md border
          ${meta.error && meta.touched ? `border-red-700` : `border-zinc-300`} bg-white p-3 text-lg text-brand-accent-gray-light placeholder:text-base placeholder:text-brand-accent-gray-light focus:text-brand-accent-default focus:outline-none focus:ring-0`,
          placeholder: label
        }))
      }),
      helpertext && !meta.touched && /* @__PURE__ */ jsx11("span", {
        className: "text-xs text-slate-600",
        children: helpertext
      }),
      meta.error && meta.touched && /* @__PURE__ */ jsx11("span", {
        className: "pl-3 text-xs text-red-700",
        children: meta.error
      })
    ]
  });
}
TextArea.displayName = "TextArea";

// src/components/FileUpload/FileUpload.tsx
import { useCallback as useCallback2, useState as useState2 } from "react";
import { useDropzone } from "react-dropzone";
import { Fragment as Fragment2, jsx as jsx12, jsxs as jsxs8 } from "react/jsx-runtime";
function FileUpload({
  input,
  label
}) {
  var _a;
  const [error, setError] = useState2("");
  const onDrop = useCallback2(
    (acceptedFiles) => {
      input.onChange(acceptedFiles);
      setError("");
    },
    [input]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 1e6,
    multiple: false,
    accept: {
      "application/pdf": [".pdf"]
    },
    onDropRejected(fileRejections, event) {
      if (fileRejections[0].errors[0].code === "file-too-large") {
        const err = `Oops! This document is larger than our 5mb size limit.
        
 Please upload a PDF that\u2019s under 500kb or email the file to new_accounts_1@hillspet.com, including your business name in the subject line of the email. To continue with the form, you will need to change your answer to this question. Thank you for your cooperation!`;
        setError(err);
      } else if (fileRejections[0].errors[0].code === "too-many-files") {
        setError("Too many files");
      } else if (fileRejections[0].errors[0].code === "file-invalid-type") {
        setError("File type is not supported");
      }
    }
  });
  return /* @__PURE__ */ jsxs8(Fragment2, {
    children: [
      /* @__PURE__ */ jsxs8("div", __spreadProps(__spreadValues({}, getRootProps()), {
        className: "mt-3 rounded border border-dashed border-brand-primary p-8 text-center",
        children: [
          /* @__PURE__ */ jsx12("div", {
            className: "mx-auto h-9 w-10",
            children: /* @__PURE__ */ jsx12(IconUpload_default, {})
          }),
          /* @__PURE__ */ jsx12("input", __spreadValues({}, getInputProps())),
          /* @__PURE__ */ jsxs8("p", {
            className: "mb-2 mt-3",
            children: [
              "Drag and drop or",
              " ",
              /* @__PURE__ */ jsx12("span", {
                className: "cursor-pointer text-brand-primary hover:underline",
                children: "browse"
              }),
              " ",
              "your files."
            ]
          }),
          /* @__PURE__ */ jsx12("p", {
            className: "text-xs text-brand-accent-gray-light",
            children: label
          })
        ]
      })),
      error && /* @__PURE__ */ jsx12("p", {
        className: "pt-2 pl-3 text-red-700",
        children: error.split("\n").map((e, index) => index === 0 ? /* @__PURE__ */ jsx12("p", {
          style: { lineHeight: 4 },
          children: e
        }) : /* @__PURE__ */ jsx12("p", {
          style: { lineHeight: 1.5 },
          children: /* @__PURE__ */ jsx12("b", {
            children: e
          })
        }))
      }),
      Array.isArray(input.value) && ((_a = input == null ? void 0 : input.value) == null ? void 0 : _a.map((file) => /* @__PURE__ */ jsxs8("div", {
        className: "mt-3 flex items-center justify-between",
        children: [
          /* @__PURE__ */ jsxs8("div", {
            className: "flex items-center gap-4",
            children: [
              /* @__PURE__ */ jsx12("span", {
                className: "h-6 w-6",
                children: /* @__PURE__ */ jsx12(IconPdf_default, {})
              }),
              /* @__PURE__ */ jsx12("span", {
                className: "text-brand-accent-gray-light",
                children: file.name
              })
            ]
          }),
          /* @__PURE__ */ jsx12("div", {
            className: "h-[20px] w-[18px] cursor-pointer",
            onClick: () => input.onChange(""),
            children: /* @__PURE__ */ jsx12(IconTrash_default, {})
          })
        ]
      }, file.path)))
    ]
  });
}
FileUpload.displayName = "FileUpload";

// src/components/SelectDropDown/SelectDropDown.tsx
import { useState as useState3 } from "react";
import { jsx as jsx13, jsxs as jsxs9 } from "react/jsx-runtime";
function SelectDropDown({
  input,
  label,
  selectOptions = [],
  meta,
  disabled
}) {
  const [value, setValue] = useState3(input.value);
  const [open, setOpen] = useState3(false);
  return /* @__PURE__ */ jsxs9("div", {
    className: "relative",
    children: [
      /* @__PURE__ */ jsx13("label", {
        htmlFor: label,
        className: `${meta.error && meta.touched && input.value !== "" ? `text-red-700` : `text-brand-accent-gray-light`} absolute  ${!value ? "top-2 left-3" : `top-4 left-3 z-10 origin-[0] -translate-y-4 scale-75  transform  duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-brand-accent-default`}`,
        children: label
      }),
      open ? /* @__PURE__ */ jsx13("div", {
        className: "absolute top-5 right-5 h-3 w-3",
        children: /* @__PURE__ */ jsx13(ExpandedUpIcon_default, {})
      }) : /* @__PURE__ */ jsx13("div", {
        className: "absolute top-5 right-5 h-3 w-3",
        children: /* @__PURE__ */ jsx13(ExpandedDownIcon_default, {})
      }),
      /* @__PURE__ */ jsxs9("select", __spreadProps(__spreadValues({}, input), {
        disabled,
        className: `w-full cursor-pointer overflow-hidden text-ellipsis rounded-md border  ${disabled ? "cursor-not-allowed bg-slate-100" : "bg-white"} ${meta.error && meta.touched ? "border-red-700" : "border-zinc-300"} px-3 pt-5 pb-1 text-lg  text-brand-accent-gray-light   focus:outline-none 
        active:border-brand-primary active:outline-none`,
        name: label,
        id: label,
        value,
        onClick: () => setOpen(!open),
        onChange: (e) => {
          setValue(e.target.value);
          input.onChange(e.target.value);
        },
        children: [
          /* @__PURE__ */ jsx13("option", {
            selected: true,
            disabled: true,
            hidden: true
          }),
          selectOptions.map((option, index) => {
            return /* @__PURE__ */ jsx13("option", {
              className: "w-max pt-5 hover:bg-brand-secondary",
              value: option.value,
              onClick: () => setOpen(!open),
              children: option.label
            }, index);
          })
        ]
      })),
      meta.touched && meta.error && /* @__PURE__ */ jsx13("span", {
        className: "text-xs text-red-700",
        children: meta.error
      })
    ]
  });
}

// src/components/Stepper/Step.tsx
import { jsx as jsx14 } from "react/jsx-runtime";
var Step = ({ stepNumber, status, totalSteps }) => {
  const stepClasses = status === 1 /* ACTIVE */ ? "bg-white text-brand-primary border-2  rounded-full border-brand-primary" : status === 2 /* COMPLETED */ ? "bg-brand-primary border-2 text-white rounded-full border-brand-primary" : "bg-white border-2  text-brand-tertiary border-brand-tertiary  rounded-full";
  const isFirstStep = stepNumber === 1;
  const isLastStep = stepNumber === totalSteps;
  const beforeClasses = `before:absolute before:right-2/4 before:top-1/2 before:h-[2px] before:w-1/2   before:-translate-y-2/4 before:content-[''] ${status === 2 /* COMPLETED */ || status === 1 /* ACTIVE */ ? "before:bg-brand-primary" : "before:bg-brand-tertiary"}`;
  const afterClasses = `after:absolute after:left-2/4 after:top-1/2 after:h-[2px] after:w-1/2  after:-translate-y-2/4 after:content-[''] ${status === 2 /* COMPLETED */ || status === 1 /* ACTIVE */ ? "after:bg-brand-primary" : "after:bg-brand-tertiary"}`;
  return /* @__PURE__ */ jsx14("div", {
    className: `relative flex w-16 flex-col items-center justify-center md:w-20 ${isFirstStep ? "" : beforeClasses} ${isLastStep ? "" : afterClasses}`,
    children: /* @__PURE__ */ jsx14("div", {
      className: `relative z-10 flex h-8 w-8 items-center justify-center text-lg font-semibold ${stepClasses}`,
      children: status === 2 /* COMPLETED */ ? /* @__PURE__ */ jsx14("div", {
        className: "text-white",
        children: /* @__PURE__ */ jsx14(CheckMark, {
          variant: "primary"
        })
      }) : stepNumber
    })
  });
};
var Step_default = Step;

// src/components/Stepper/Stepper.tsx
import { jsx as jsx15 } from "react/jsx-runtime";
var Status = /* @__PURE__ */ ((Status2) => {
  Status2[Status2["PENDING"] = 0] = "PENDING";
  Status2[Status2["ACTIVE"] = 1] = "ACTIVE";
  Status2[Status2["COMPLETED"] = 2] = "COMPLETED";
  return Status2;
})(Status || {});
var Stepper = ({ currentStep = 1, totalSteps = 5 }) => {
  const steps = Array.from(Array(totalSteps).keys());
  return /* @__PURE__ */ jsx15("div", {
    className: `flex justify-between mt-4`,
    children: steps == null ? void 0 : steps.map((step, i) => {
      const status = currentStep === i + 1 ? 1 /* ACTIVE */ : currentStep > i + 1 ? 2 /* COMPLETED */ : 0 /* PENDING */;
      return /* @__PURE__ */ jsx15(Step_default, {
        status,
        stepNumber: i + 1,
        totalSteps
      });
    })
  });
};
Stepper.displayname = "Stepper";

// src/components/Checkbox/Checkbox.tsx
import { jsx as jsx16, jsxs as jsxs10 } from "react/jsx-runtime";
function Checkbox(_a) {
  var _b = _a, {
    input,
    type,
    meta,
    id,
    label,
    htmlFor,
    helpertext
  } = _b, rest = __objRest(_b, [
    "input",
    "type",
    "meta",
    "id",
    "label",
    "htmlFor",
    "helpertext"
  ]);
  return /* @__PURE__ */ jsxs10("label", {
    className: "relative flex cursor-pointer items-center gap-5 p-3 text-gray-500 hover:rounded-md hover:bg-white hover:shadow-[2px_2px_10px_rgba(0,0,0,0.3)]",
    children: [
      /* @__PURE__ */ jsx16("input", __spreadProps(__spreadValues(__spreadValues({}, input), rest), {
        id,
        className: "min-h-[20px] min-w-[20px] cursor-pointer rounded border-gray-300 bg-gray-100 accent-brand-primary focus:ring-2 active:bg-brand-primary/[0.12] active:shadow-none"
      })),
      /* @__PURE__ */ jsxs10("p", {
        className: "cursor-pointer",
        children: [
          label,
          /* @__PURE__ */ jsx16("span", {
            className: "block text-xs text-gray-500",
            children: helpertext
          })
        ]
      })
    ]
  });
}

// src/components/Table/Table.tsx
import { jsx as jsx17, jsxs as jsxs11 } from "react/jsx-runtime";
var Table = ({
  headerItems,
  data,
  EmptyRowMessage
}) => /* @__PURE__ */ jsxs11("table", {
  className: "block min-w-full border-collapse md:table",
  children: [
    /* @__PURE__ */ jsx17("thead", {
      className: "block md:table-header-group",
      children: /* @__PURE__ */ jsx17("tr", {
        className: "absolute -top-full -left-full block md:relative md:top-auto md:left-auto  md:table-row ",
        children: headerItems.map((column, index) => {
          return /* @__PURE__ */ jsx17("th", {
            className: index === 0 ? "block pr-24 text-left font-bold md:table-cell md:w-80" : "block  pr-4 text-left text-xs font-bold md:table-cell md:w-11",
            "data-testid": "table-header",
            children: column
          }, column);
        })
      })
    }),
    /* @__PURE__ */ jsx17("tbody", {
      className: "block md:table-row-group",
      children: data.length ? data.map((row, index) => /* @__PURE__ */ jsx17("tr", {
        className: "mt-3 block  border-b-4 border-white bg-brand-secondary md:table-row",
        children: row.map((cell, index2) => /* @__PURE__ */ jsx17("td", {
          className: "block  p-2 md:table-cell",
          children: /* @__PURE__ */ jsxs11("div", {
            className: "flex  gap-3 md:block",
            children: [
              /* @__PURE__ */ jsx17("div", {
                className: "mt-4 w-1/3 justify-self-center  font-bold md:hidden",
                children: headerItems[index2]
              }),
              /* @__PURE__ */ jsx17("div", {
                className: "mt-4 flex flex-col md:block",
                children: cell
              })
            ]
          })
        }, index2))
      }, index)) : EmptyRowMessage
    })
  ]
});

// src/components/Chip/Chip.tsx
import { jsx as jsx18, jsxs as jsxs12 } from "react/jsx-runtime";
var Chip = ({ data, handleDelete }) => {
  return /* @__PURE__ */ jsxs12("button", {
    className: "flex cursor-pointer rounded-full border bg-white py-2 px-4 font-bold  text-black hover:shadow-md focus:outline-dashed focus:outline-2 \n    focus:outline-offset-2 focus:outline-brand-primary active:bg-brand-primary/[0.12] active:shadow-none active:outline-none",
    children: [
      /* @__PURE__ */ jsx18("span", {
        children: data
      }),
      /* @__PURE__ */ jsx18("span", {
        className: "m-1 h-4 w-4",
        onClick: handleDelete,
        children: /* @__PURE__ */ jsx18(CrossIcon_default, {})
      })
    ]
  });
};
export {
  AlertDialog,
  Button,
  CheckMarckIconWithLabel,
  CheckMark,
  Checkbox,
  Chip,
  EditIcon_default as EditIcon,
  FileUpload,
  FormControl,
  FormGroup,
  IconEye_default as IconEye,
  IconInformationCircleFilled_default as IconInformationCircleFilled,
  IconPersonOutline_default as IconPersonOutline,
  IconWarning_default as IconWarning,
  Input,
  PlusIcon_default as PlusIcon,
  Radio,
  SelectDropDown,
  Status,
  Stepper,
  Table,
  TextArea,
  TextLink,
  TrashIcon_default as TrashIcon,
  WrapperContainer,
  composeValidators,
  emojiValidation,
  equalValue,
  maxLength,
  minLength,
  minValue,
  mustBeNumber,
  onlyCharValidation,
  required
};
