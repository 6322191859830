import { QuestionsInterface } from "."
import { PaymentMethod } from "../types"
import {
  mustBeValidName,
  required,
  validAccountNumber,
  validRoutingNumber,
} from "../utils/validators"

export const HEADER = "Payment Information"

export enum PreferredPaymentType {
  AUTO_DEBIT_20_DAYS = "AUTO_DEBIT_20_DAYS",
  AUTO_DEBIT_NEXT_DAY = "AUTO_DEBIT_NEXT_DAY",
  MAIL_CHECK_20_DAYS = "MAIL_CHECK_20_DAYS",
  CREDIT_CARD_NEXT_DAY_POST_INVOICE = "CREDIT_CARD_NEXT_DAY_POST_INVOICE",
  AUTO_DEBIT_30_DAYS = "AUTO_DEBIT_30_DAYS",
  MAIL_CHECK_30_DAYS = "MAIL_CHECK_30_DAYS",
}

export const PREFERRED_PAYMENT_MAP = {
  AUTO_DEBIT_20_DAYS:
    "Auto-Draft from Checking Account on the 20th of Each Month",
  AUTO_DEBIT_NEXT_DAY: "Auto-Draft from Checking Account Next Day Post-Invoice",
  MAIL_CHECK_20_DAYS:
    "Electronic Check or Mailed Check Due on the 20th of Each Month",
  CREDIT_CARD_NEXT_DAY_POST_INVOICE: "Credit Card Next Day Post-Invoice",
  AUTO_DEBIT_30_DAYS:
    "Auto-Draft from Checking Account 30 Days from Date of Invoice",
  MAIL_CHECK_30_DAYS:
    "Electronic Check or Mailed Check Due 30 Days from Date of Invoice",
}

export const PAYMENT_INFO_VET_QUESTIONS: QuestionsInterface<PaymentMethod>[] = [
  {
    QUESTION_TEXT: "What is your preferred method of payment?",
    OPTIONS: [
      {
        componentType: "Radio",
        id: "auto-debit-after-20-days",
        name: "paymentMethod",
        label: "Auto-Draft from Checking Account on the 20th of Each Month",
        type: "radio",
        value: "AUTO_DEBIT_20_DAYS",
      },
      {
        componentType: "Radio",
        id: "auto-debit-next-day",
        name: "paymentMethod",
        label: "Auto-Draft from Checking Account Next Day Post-Invoice",
        type: "radio",
        helperText: "(Automated Clearinghouse [ACH] Next Day Post-Invoice)",
        value: "AUTO_DEBIT_NEXT_DAY",
      },
      {
        componentType: "Radio",
        id: "mail-check-twenteeth-each-month",
        name: "paymentMethod",
        label: "Electronic Check or Mailed Check Due on the 20th of Each Month",
        type: "radio",
        helperText:
          "(Please contact the Finance team for processing electronic checks. Payments are due based on the due date on your invoice.)",
        value: "MAIL_CHECK_20_DAYS",
      },
      {
        componentType: "Radio",
        id: "credit-card-next-day-post-invoice",
        name: "paymentMethod",
        label: "Credit Card Next Day Post-Invoice",
        type: "radio",
        helperText:
          "(You will receive an email in 2-3 business days that will allow you to securely provide your credit card information.)",
        value: "CREDIT_CARD_NEXT_DAY_POST_INVOICE",
      },
    ],
    VALIDATION: [required],
    SUB_QUESTIONS: [
      {
        EXPECTED_PARENT_VALUE: ["AUTO_DEBIT_20_DAYS", "AUTO_DEBIT_NEXT_DAY"],
        QUESTIONS: [
          {
            QUESTION_TEXT: "Please enter your banking information",
            OPTIONS: [
              {
                componentType: "Input",
                label: "Account Number",
                id: "accountNumber",
                name: "accountNumber",
                type: "text",
                surfly_private: "true",
                VALIDATION: [required, validAccountNumber],
              },
              {
                componentType: "Input",
                label: "Routing Number",
                id: "routingNumber",
                name: "routingNumber",
                type: "text",
                surfly_private: "true",
                VALIDATION: [required, validRoutingNumber],
              },
              {
                componentType: "Input",
                label: "Bank Name",
                id: "bankName",
                name: "bankName",
                type: "text",
                surfly_private: "true",
                VALIDATION: [required, mustBeValidName('bankName')],
              },
            ],
          },
        ],
      },
    ],
  },
]

export const PAYMENT_INFO_PET_QUESTIONS: QuestionsInterface<PaymentMethod>[] = [
  {
    QUESTION_TEXT: "What is your preferred method of payment?",
    OPTIONS: [
      {
        componentType: "Radio",
        id: "auto-debit-after-30-days",
        name: "paymentMethod",
        label: "Auto-Draft from Checking Account 30 Days from Date of Invoice",
        type: "radio",
        value: "AUTO_DEBIT_30_DAYS",
        helperText: "(Automated Clearinghouse [ACH] Net 30 Days)",
      },
      {
        componentType: "Radio",
        id: "auto-debit-next-day",
        name: "paymentMethod",
        label: "Auto-Draft from Checking Account Next Day Post-Invoice",
        type: "radio",
        helperText: "(Automated Clearinghouse [ACH] Next Day Post-Invoice)",
        value: "AUTO_DEBIT_NEXT_DAY",
      },
      {
        componentType: "Radio",
        id: "mail-check-thirteeth-each-month",
        name: "paymentMethod",
        label:
          "Electronic Check or Mailed Check Due 30 Days from Date of Invoice",
        type: "radio",
        helperText:
          "(Please contact the Finance team for processing electronic checks. Payments are due based on the due date on your invoice.)",
        value: "MAIL_CHECK_30_DAYS",
      },
    ],
    VALIDATION: [required],
    SUB_QUESTIONS: [
      {
        EXPECTED_PARENT_VALUE: ["AUTO_DEBIT_30_DAYS", "AUTO_DEBIT_NEXT_DAY"],
        QUESTIONS: [
          {
            QUESTION_TEXT: "Please enter your banking information",
            OPTIONS: [
              {
                componentType: "Input",
                label: "Account Number",
                id: "accountNumber",
                name: "accountNumber",
                type: "text",
                surfly_private: "true",
                VALIDATION: [required, validAccountNumber],
              },
              {
                componentType: "Input",
                label: "Routing Number",
                id: "routingNumber",
                name: "routingNumber",
                type: "text",
                surfly_private: "true",
                VALIDATION: [required, validRoutingNumber],
              },
              {
                componentType: "Input",
                label: "Bank Name",
                id: "bankName",
                name: "bankName",
                type: "text",
                surfly_private: "true",
                VALIDATION: [required],
              },
            ],
          },
        ],
      },
    ],
  },
]
