import {useContext, useState, useEffect} from "react"
import {useNavigate, useLocation} from "react-router-dom"
import axios from "axios"
import {Form} from 'react-final-form'
import {Button} from "ui"
import {useAuth} from "../../hooks/use-auth"
import {CUSTOMER_NOTES_QUESTION, CUSTOMER_NOTES_TITLE, REVIEW_TIP} from "../../constants/review"
import BusinessInformation from "./BusinessInformation"
import OrderInformation from "./OrderInformation"
import PaymentInformation from "./PaymentInformation"
import ShippingInformation from "./ShippingInformation"
import DateSignInformation from "./DateSignInformation"
import {NACEvents, NACStates} from "../../machine/nac-machine.types"
import {AddShipTo} from "../../types/"
import {nacMachineContext} from "../../context"
import {TransactionType} from "../../constants/select-transaction"
import {RoutePaths, FormButton, AccountType, SPINNER, SESSION_EXPIRED_ERROR, ERROR} from "../../constants"
import {transform} from "../utils/transform"
import {Spinner} from "../Spinner"
import ErrorPage from "../ErrorPage"
import SessionExpired from "../ErrorPage/SessionExpired"
import Question from "../utils/Question"
import { trackFailedSubmision } from "../../utils/trackEvents"

const Review = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const auth: any = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [viewAllShipTo, setViewAllShipTo] = useState<boolean>(false)
  const {state, send} = useContext(nacMachineContext) || {}
  const navigate = useNavigate()
  const location = useLocation()
  const [hasError, setHasError] = useState<boolean>(false)
  const [sessionError, setSessionError] = useState<boolean>(false)

  useEffect(() => {
    if (state?.value !== NACStates.review) {
      send(NACEvents.INIT, {data: {route: location.pathname}})
    }
  }, [])

  if (isLoading) {
    return <Spinner loadingMessage={SPINNER.submitting} />
  }

  if (hasError) {
    return (
      <ErrorPage
        handleTryAgain={() => {
          setHasError(false)
          setIsLoading(true)

          createAgreement()
        }}
      />
    )
  }
  if (sessionError) {
    return <SessionExpired />
  }

  function createAgreement() {
    const formData = new FormData()
    const {transformedPayload: payload, files} = transform(state.context)

    // remove sensitive info from payload
    delete payload?.["paymentDetails"]
    delete payload?.businessInformation?.["identificationInformation"]
    formData.append("data", JSON.stringify(payload))

    for (const file of files) {
      formData.append("files", new File(file, file.name))
    }
    const token = localStorage.getItem("gigyaToken")
    const authHeader = token ? {Authorization: `Bearer ${token}`} : undefined

    axios
      .post(
        `${process.env.REACT_APP_CUSTOMER_SVC_URL}/create-agreement`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            ...authHeader,
          },
        },
      )
      .then(res => {
        if (res.status === 200) {
          send(NACEvents.NEXT, {
            data: {
              signUrl: res.data.signUrl,
              agreementId: res.data.agreementId,
            },
          })
          setIsLoading(false)
          navigate(RoutePaths.SIGN)
        }
      })
      .catch(err => {
        setIsLoading(false);
        const isExpired = err.response?.status === 401 || err.response?.status === 403;
        
        trackFailedSubmision('create-agreement', isExpired ? SESSION_EXPIRED_ERROR.header : ERROR.header);

        if (isExpired) {
          setSessionError(true)
          auth.handleLogout()
        } else {
          setHasError(true)
        }
      })
  }

  const showBusinessInfo = [
    TransactionType.CreateNewCustomerAccount,
    TransactionType.TransferOwnership,
    TransactionType.UpdateCustomerAccountProfile,
  ].includes(Number(state.context.selectedTransaction))

  const ignoreOrderInfo = [AccountType.VET_TECH].includes(
    Number(state.context.accountType),
  )
  const ignorePaymentInfo = [
    AccountType.VET_TECH,
    AccountType.MILITARY,
    AccountType.LAW_ENFORCEMENT,
  ].includes(Number(state.context.accountType))

  const onSubmit = () => {
    setIsLoading(true)
    createAgreement()
  }

  return (
    <div className="flex justify-center px-2 lg:px-0">
      <div className="w-full  lg:w-[1064px] lg:px-8">
        <div className="mt-10 rounded bg-brand-primary px-4 py-5 text-sm leading-5 text-white">
          <span className="font-bold">{REVIEW_TIP.title}</span>
          <span className="font-light">{REVIEW_TIP.description}</span>
        </div>
        {showBusinessInfo && state?.context?.businessInformation && (
          <BusinessInformation />
        )}
        {showBusinessInfo &&
          !ignoreOrderInfo &&
          state?.context?.financialInformation && <OrderInformation />}
        {showBusinessInfo &&
          !ignorePaymentInfo &&
          state?.context?.paymentInformation && <PaymentInformation />}
        {state?.context?.addShipTo &&
          state?.context?.addShipTo?.map((shipTo: AddShipTo, index: number) => {
            return (
              <div
                key={index}
                className={`${
                  index === 0 ? `block` : viewAllShipTo ? `block` : `hidden`
                }`}
              >
                <ShippingInformation
                  shipTo={shipTo}
                  index={index}
                  length={state.context.addShipTo.length}
                  viewAllShipTo={viewAllShipTo}
                  onSetViewAllShipTo={setViewAllShipTo}
                />
              </div>
            )
          })}
        <DateSignInformation />
        <Form
          onSubmit={onSubmit}
          render={({handleSubmit, invalid, values}) => {
            return (
              <form onSubmit={handleSubmit}>
                <section className="mt-10 pt-3">
                  <div className="flex justify-between pb-5">
                    <h2 className="text-lg font-bold">{CUSTOMER_NOTES_TITLE}</h2>
                  </div>
                  <div className="border-t border-gray-300">
                    <div className="mt-6 grid grid-cols-2 gap-6 lg:grid-cols-1"
                      onKeyUp={() => {
                        send(NACEvents.CLICK, {data: values.custNotes})
                      }}
                    >
                      <Question
                        questions={CUSTOMER_NOTES_QUESTION}
                        values={values}
                      />
                    </div>
                  </div>
                </section>
                <div className="my-10 flex justify-end gap-8">
                  <Button
                    variant="text"
                    onClick={() => {
                      send(NACEvents.BACK)
                      navigate(RoutePaths.DATE_SIGN)
                    }}
                  >
                    {FormButton.BACK}
                  </Button>
                  <Button
                    variant="primary"
                    mode="filled"
                    type="submit"
                    disabled={invalid}
                  >
                    {FormButton.SIGN_CONTRACT}
                  </Button>
                </div>
              </form>
            )
          }} 
        />
      </div>
    </div>
  )
}

export default Review
