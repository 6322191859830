import {useContext, useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import axios from "axios"
import {useAuth} from "../../hooks/use-auth"
import {nacMachineContext} from "../../context"
import {RoutePaths, SPINNER, AccountType, SESSION_EXPIRED_ERROR, ERROR} from "../../constants"
import {NACEvents} from "../../machine"
import {Spinner} from "../Spinner"
import ErrorPage from "../ErrorPage"
import SessionExpired from "../ErrorPage/SessionExpired"
import {transform} from "../utils/transform"
import { trackFailedSubmision, trackSuccessfulAccountCreation } from "../../utils/trackEvents"

type Payload = {
  agreementId: string
  paymentDetails?: Record<string, unknown>
  identificationInformation?: Record<string, unknown>
}

const Sign = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const auth: any = useAuth()
  const {state, send} = useContext(nacMachineContext) || {}
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hasError, setHasError] = useState<boolean>(false)
  const [sessionError, setSessionError] = useState<boolean>(false)

  function saveCustomerData() {
    const {transformedPayload} = transform(state.context)
    const token = localStorage.getItem("gigyaToken")
    const headers = token ? {Authorization: `Bearer ${token}`} : undefined
    const payload: Payload = {
      agreementId: state?.context?.review?.agreementId,
    }

    if (transformedPayload?.paymentDetails)
      payload.paymentDetails = transformedPayload?.paymentDetails

    if (transformedPayload?.businessInformation?.identificationInformation)
      payload.identificationInformation =
        transformedPayload?.businessInformation?.identificationInformation

    axios
      .post(
        `${process.env.REACT_APP_CUSTOMER_SVC_URL}/save-customer-data`,
        {
          ...payload,
        },
        {
          headers: headers,
        },
      )
      .then(res => {
        if (res.status === 200) {
          trackSuccessfulAccountCreation(res.data.aodocsid, AccountType[parseInt(state.context.accountType)]);

          setIsLoading(false)
          send(NACEvents.NEXT)
          navigate(RoutePaths.THANK_YOU)
        }
      })
      .catch(err => {
        setIsLoading(false);
        const isExpired = err.response?.status === 401 || err.response?.status === 403;

        trackFailedSubmision('save-customer-data', isExpired ? SESSION_EXPIRED_ERROR.header : ERROR.header);

        if (isExpired) {
          setSessionError(true)
          auth.handleLogout()
        } else {
          setHasError(true)
        }
      })
  }

  useEffect(() => {
    window.onmessage = event => {
      if (event.origin == process.env.REACT_APP_ADOBESIGN_URL) {
        if (JSON.parse(event.data).type === "ESIGN") {
          setIsLoading(true)
          saveCustomerData()
        }
      }
    }
  }, [isLoading, hasError])

  return isLoading ? (
    <Spinner loadingMessage={SPINNER.processing} />
  ) : hasError ? (
    <ErrorPage
      handleTryAgain={() => {
        setHasError(false)
        setIsLoading(true)
        saveCustomerData()
      }}
    />
  ) : sessionError ? (
    <SessionExpired />
  ) : (
    <div className="w-full lg:px-8">
      <iframe
        src={state?.context?.review?.signUrl}
        className="mx-auto] h-[80vh] w-[80vw] lg:w-full"
      ></iframe>
    </div>
  )
}

export default Sign
