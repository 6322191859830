import {
  Options,
  QuestionOptionsInterface,
  QuestionsInterface,
  SubQuestions,
  US_STATES,
} from "."
import {
  maxLength,
  mustBeEmail,
  mustBePhone,
  required,
  mustBeZipCode,
} from "../utils/validators"
import {formatZip, formatPhone} from "./../components/utils/format"

export const HEADER = "Ordering Information"

export const PAYMENT_TERMS_HEADER = "Payment Terms"
export const VET_PAYMENT_TERMS =
  "Your payment will be due on the 20th of each month."
export const PET_PAYMENT_TERMS =
  "Your payment will be due 30 days from the date of invoice."

const SUB_QUESTIONS_INVOICE: SubQuestions<Options>[] = [
  {
    EXPECTED_PARENT_VALUE: [Options.YES],
    QUESTIONS: [
      {
        QUESTION_TEXT: "",
        VALIDATION: [required],
        OPTIONS: [
          {
            label: "Email for Electronic Invoices",
            name: "invoiceemail",
            id: "invoiceemail",
            type: "text",
            componentType: "Input",
            VALIDATION: [required, mustBeEmail],
          },
        ],
      },
    ],
  },
]

const SUB_QUESTIONS_ORDER_CONFIRMATION: SubQuestions<Options>[] = [
  {
    EXPECTED_PARENT_VALUE: [Options.YES],
    QUESTIONS: [
      {
        QUESTION_TEXT: "",
        VALIDATION: [required],
        OPTIONS: [
          {
            label: "Email for Order Confirmations",
            name: "orderConfirmationEmail",
            id: "orderConfirmationEmail",
            type: "text",
            componentType: "Input",
            VALIDATION: [required, mustBeEmail],
          },
        ],
      },
    ],
  },
]

const SUB_QUESTIONS_BPO: SubQuestions<Options>[] = [
  {
    EXPECTED_PARENT_VALUE: [Options.YES],
    QUESTIONS: [
      {
        QUESTION_TEXT: "",
        VALIDATION: [required],
        OPTIONS: [
          {
            label: "BPO Number",
            name: "bpo",
            id: "bpo",
            type: "text",
            componentType: "Input",
            VALIDATION: [required],
          },
        ],
      },
    ],
  },
]

export const BILLING_ADDRESS_QUESTIONS: QuestionsInterface<Options>[] = [
  {
    QUESTION_TEXT: "Please enter your billing address",
    OPTIONS: [
      {
        label: "Street Address",
        name: "streetAddress",
        id: "streetAddress",
        type: "text",
        componentType: "Input",
        dataTestId: "streetAddress",
        VALIDATION: [required],
      },
      {
        label: "Apartment, Suite, Etc.",
        name: "apartment",
        id: "apartment",
        type: "text",
        componentType: "Input",
        dataTestId: "apartment",
      },
      {
        label: "City",
        name: "city",
        id: "city",
        type: "text",
        componentType: "Input",
        dataTestId: "city",
        VALIDATION: [required],
      },
    ],
  },
]

export const BILLING_ADDRESS_QUESTIONS_DISABLED: QuestionsInterface<Options>[] =
  [
    {
      QUESTION_TEXT: "Please enter your billing address",
      OPTIONS: [
        {
          label: "Street Address",
          name: "streetAddress",
          id: "streetAddress",
          type: "text",
          componentType: "Input",
          dataTestId: "streetAddress",
          disabled: true,
          warningtext:
            "To change your billing address, please contact Hill’s New Accounts team at 1 (800) 354-4557 (select option 2 and then option 7) or via email at new_accounts_1@hillspet.com.",
        },
        {
          label: "Apartment, Suite, Etc.",
          name: "apartment",
          id: "apartment",
          type: "text",
          componentType: "Input",
          dataTestId: "apartment",
          disabled: true,
        },
        {
          label: "City",
          name: "city",
          id: "city",
          type: "text",
          componentType: "Input",
          dataTestId: "city",
          disabled: true,
        },
      ],
    },
  ]

export const BILLING_ZIP_CODE: QuestionOptionsInterface = {
  label: "ZIP Code",
  name: "zipcode",
  id: "zipcode",
  type: "text",
  componentType: "Input",
  dataTestId: "zipcode",
  helperText: "e.g. 21354-6879",
  VALIDATION: [required, mustBeZipCode],
  format: formatZip,
}

export const BILLING_ZIP_CODE_DISABLED: QuestionOptionsInterface = {
  label: "ZIP Code",
  name: "zipcode",
  id: "zipcode",
  type: "text",
  componentType: "Input",
  dataTestId: "zipcode",
  helperText: "e.g. 21354-6879",
  format: formatZip,
  disabled: true,
}

export const BILLING_STATE: QuestionOptionsInterface = {
  componentType: "SelectDropDown",
  label: "State",
  id: "state",
  name: "state",
  type: "select",
  options: US_STATES,
  VALIDATION: [required],
}

export const BILLING_STATE_DISABLED: QuestionOptionsInterface = {
  componentType: "SelectDropDown",
  label: "State",
  id: "state",
  name: "state",
  type: "select",
  options: US_STATES,
  disabled: true,
}

export const BILLING_CONTACT_DETAILS: QuestionsInterface<Options>[] = [
  {
    QUESTION_TEXT: "Who is the purchasing agent that will be placing orders?",
    VALIDATION: [required],
    OPTIONS: [
      {
        label: "Purchasing Agent First Name",
        name: "agentFirstName",
        id: "agentFirstName",
        type: "text",
        componentType: "Input",
        dataTestId: "purchasingAgentfirstName",
        VALIDATION: [required],
      },
      {
        label: "Purchasing Agent Last Name",
        name: "agentLastName",
        id: "agentLastName",
        type: "text",
        componentType: "Input",
        dataTestId: "purchasinglastAgentName",
        VALIDATION: [required],
      },
      {
        label: "Purchasing Agent Phone Number",
        name: "agentPhoneNumber",
        id: "agentPhoneNumber",
        type: "text",
        componentType: "Input",
        dataTestId: "purchasingAgentPhoneNumber",
        VALIDATION: [required, mustBePhone],
        format: formatPhone,
      },
      {
        label: "Purchasing Agent Email",
        name: "agentEmail",
        id: "agentEmail",
        type: "text",
        componentType: "Input",
        dataTestId: "purchasingAgentEmail",
        VALIDATION: [required, mustBeEmail],
      },
    ],
  },
  {
    QUESTION_TEXT: "Who is the accounts payable or billing contact?",
    OPTIONS: [
      {
        label: "Billing Contact First Name",
        name: "billingContactFirstName",
        id: "billingContactFirstName",
        type: "text",
        componentType: "Input",
        dataTestId: "billingContactfirstName",
        VALIDATION: [required],
      },
      {
        label: "Billing Contact Last Name",
        name: "billingContactLastName",
        id: "billingContactLastName",
        type: "text",
        componentType: "Input",
        dataTestId: "billingContactLastName",
        VALIDATION: [required],
      },
      {
        label: "Billing Contact Phone Number",
        name: "billingContactPhoneNumber",
        id: "billingContactPhoneNumber",
        type: "text",
        componentType: "Input",
        dataTestId: "billingContactPhoneNumber",
        VALIDATION: [required, mustBePhone],
        format: formatPhone,
      },
      {
        label: "Billing Contact Email",
        name: "billingContactEmail",
        id: "billingContactEmail",
        type: "text",
        componentType: "Input",
        dataTestId: "billingContactEmail",
        VALIDATION: [required, mustBeEmail],
      },
    ],
  },
]

export const MILITARY_BILLING_CONTACT_DETAILS: QuestionsInterface<Options>[] = [
  {
    QUESTION_TEXT: "Who is the accounts payable or billing contact?",
    OPTIONS: [
      {
        label: "Billing Contact First Name",
        name: "billingContactFirstName",
        id: "billingContactFirstName",
        type: "text",
        componentType: "Input",
        dataTestId: "billingContactfirstName",
        VALIDATION: [required],
      },
      {
        label: "Billing Contact Last Name",
        name: "billingContactLastName",
        id: "billingContactLastName",
        type: "text",
        componentType: "Input",
        dataTestId: "billingContactLastName",
        VALIDATION: [required],
      },
      {
        label: "Billing Contact Phone Number",
        name: "billingContactPhoneNumber",
        id: "billingContactPhoneNumber",
        type: "text",
        componentType: "Input",
        dataTestId: "billingContactPhoneNumber",
        VALIDATION: [required, mustBePhone],
        format: formatPhone,
      },
      {
        label: "Billing Contact Email",
        name: "billingContactEmail",
        id: "billingContactEmail",
        type: "text",
        componentType: "Input",
        dataTestId: "billingContactEmail",
        VALIDATION: [required, mustBeEmail],
      },
    ],
  },
]

export const INVOICE_CONFIRMATION_QUESTIONS: QuestionsInterface<Options>[] = [
  {
    QUESTION_TEXT: "Would you like invoices to be sent electronically?",
    VALIDATION: [required],
    OPTIONS: [
      {
        label: "Yes",
        value: Options.YES,
        name: "invoiceConfirmation",
        id: "invoice-yes",
        dataTestId: "invoice-yes",
        type: "radio",
        componentType: "Radio",
      },
      {
        label: "No",
        value: Options.NO,
        name: "invoiceConfirmation",
        id: "invoice-no",
        dataTestId: "invoice-no",
        type: "radio",
        componentType: "Radio",
      },
    ],
    SUB_QUESTIONS: [...SUB_QUESTIONS_INVOICE],
  },
  {
    QUESTION_TEXT:
      "Would you like order confirmations to be sent electronically?",
    VALIDATION: [required],
    OPTIONS: [
      {
        label: "Yes",
        value: Options.YES,
        name: "orderConfirmation",
        id: "orderconfirmation-yes",
        dataTestId: "orderconfirmation-yes",
        type: "radio",
        componentType: "Radio",
      },
      {
        label: "No",
        value: Options.NO,
        name: "orderConfirmation",
        id: "orderconfirmation-no",
        dataTestId: "orderconfirmation-no",
        type: "radio",
        componentType: "Radio",
      },
    ],
    SUB_QUESTIONS: SUB_QUESTIONS_ORDER_CONFIRMATION,
  },
]

export const VET_QUESTIONS: QuestionsInterface<Options>[] = [
  {
    QUESTION_TEXT: "What is the name of the primary veterinarian?",

    OPTIONS: [
      {
        label: "Veterinarian First Name",
        name: "vetfirstName",
        id: "vetfirstName",
        type: "text",
        componentType: "Input",
        VALIDATION: [required, maxLength('vetfirstName',50)],
      },
      {
        label: "Veterinarian Last Name and Degree",
        name: "vetlastName",
        id: "vetlastName",
        type: "text",
        componentType: "Input",
        VALIDATION: [required, maxLength('vetlastName', 50)],
      },
    ],
  },
  {
    QUESTION_TEXT: "What is the primary veterinarian’s license number?",
    VALIDATION: [required],
    OPTIONS: [
      {
        label: "Veterinarian License Number",
        name: "vetLicense",
        id: "vetLicense",
        type: "text",
        surfly_private: "true",
        componentType: "Input",
        VALIDATION: [required],
      },
    ],
  },
]

export const PO_QUESTIONS = [
  {
    QUESTION_TEXT: "Is a Blanket Purchase Order (BPO) number required?",
    VALIDATION: [required],
    OPTIONS: [
      {
        label: "Yes",
        value: Options.YES,
        name: "isPONeeded",
        id: "po-yes",
        dataTestId: "po-yes",
        type: "radio",
        componentType: "Radio",
      },
      {
        label: "No",
        value: Options.NO,
        name: "isPONeeded",
        id: "po-no",
        dataTestId: "po-no",
        type: "radio",
        componentType: "Radio",
      },
    ],
    SUB_QUESTIONS: SUB_QUESTIONS_BPO,
  },
]
