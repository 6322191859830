import {Options, QuestionsInterface, SubQuestions, US_STATES} from "."
import {
  maxLength,
  required,
  mustBeEmail,
  mustBePhone,
  mustBeEIN,
  mustBeSSN,
  mustBeValidName,
} from "../utils/validators"
import {formatSSN, formatEIN, formatPhone} from "../components/utils/format"

export const EIN_ERROR_MESSAGE =
  "Please enter equal Employer Identication Number values"
export const SSN_ERROR_MESSAGE = "Please enter equal SSN values"

export const HEADER = "Business Information"

export enum PhysicalLocation {
  SingleBusinessBuilding,
  InsideMallorAnotherBusiness,
  PersonalResidence,
  MobileVet,
}

export enum LegalBusinessForm {
  Incorporated,
  LLC,
  SoleProprietorship,
  Partnership,
  LLP,
  Other,
}

export enum BusinessInfoQuestions {
  ContactInfo,
  BusinessName,
  BusinessEstablishmentDate,
  CorporateGroupName,
  BuisnessContact,
  PhysicalLocation,
  LegalBusinessForm,
  BusinessFedTaxIdentificationNumber,
}

export const PHYSICAL_LOCATION_MAP: Record<PhysicalLocation, string> = {
  [PhysicalLocation.SingleBusinessBuilding]: "Single Business Building",
  [PhysicalLocation.InsideMallorAnotherBusiness]:
    "Inside Mall or Another Business",
  [PhysicalLocation.PersonalResidence]: "Located on Personal residence",
  [PhysicalLocation.MobileVet]: "Mobile Vet",
}

export const LEGAL_BUSINESS_FORM_MAP: Record<LegalBusinessForm, string> = {
  [LegalBusinessForm.Incorporated]: "Incorporated",
  [LegalBusinessForm.LLC]: "Limited Liability Company (LLC)",
  [LegalBusinessForm.SoleProprietorship]: "Sole Proprietorship",
  [LegalBusinessForm.Partnership]: "Partnership",
  [LegalBusinessForm.LLP]: "Limited Liability Partnership (LLP)",
  [LegalBusinessForm.Other]: "Other",
}

export const SUB_QUESTION_LEGAL_BUSINESS_FORM: SubQuestions<LegalBusinessForm>[] =
  [
    {
      EXPECTED_PARENT_VALUE: [
        LegalBusinessForm.Incorporated,
        LegalBusinessForm.LLC,
        LegalBusinessForm.Partnership,
        LegalBusinessForm.LLP,
      ],
      QUESTIONS: [
        {
          QUESTION_TEXT: "What is the date of formation?",
          OPTIONS: [
            {
              componentType: "Input",
              label: "Date of Formation",
              id: "formationDate",
              name: "formationDate",
              type: "date",
            },
          ],
          VALIDATION: [required],
        },
        {
          QUESTION_TEXT: "What is the state of formation?",
          OPTIONS: [
            {
              componentType: "SelectDropDown",
              label: "State of Formation",
              id: "state",
              name: "state",
              type: "select",
              options: US_STATES,
            },
          ],
          VALIDATION: [required],
        },
      ],
    },
    {
      EXPECTED_PARENT_VALUE: [LegalBusinessForm.Other],
      QUESTIONS: [
        {
          QUESTION_TEXT: "",
          OPTIONS: [
            {
              componentType: "Input",
              label: "Other, please specify",
              id: "other",
              name: "other",
              type: "text",
            },
          ],
        },
        {
          QUESTION_TEXT: "What is the date of formation",
          OPTIONS: [
            {
              componentType: "Input",
              label: "Date of Formation",
              id: "formationDate",
              name: "formationDate",
              type: "date",
            },
          ],
          VALIDATION: [required],
        },
      ],
    },
  ]

export const UPDATED_SUB_QUESTIONS_LEGAL_BUSINESS_FORM: SubQuestions<LegalBusinessForm>[] =
  [
    {
      EXPECTED_PARENT_VALUE: [
        LegalBusinessForm.Incorporated,
        LegalBusinessForm.LLC,
        LegalBusinessForm.Partnership,
        LegalBusinessForm.LLP,
      ],
      QUESTIONS: [
        {
          QUESTION_TEXT: "What is the updated date of formation?",
          OPTIONS: [
            {
              componentType: "Input",
              label: "Date of Formation",
              id: "formationDate",
              name: "formationDate",
              type: "date",
            },
          ],
          VALIDATION: [required],
        },
        {
          QUESTION_TEXT: "What is the updated state of formation?",
          OPTIONS: [
            {
              componentType: "SelectDropDown",
              label: "State of Formation",
              id: "state",
              name: "state",
              type: "select",
              options: US_STATES,
            },
          ],
          VALIDATION: [required],
        },
      ],
    },
    {
      EXPECTED_PARENT_VALUE: [LegalBusinessForm.Other],
      QUESTIONS: [
        {
          QUESTION_TEXT: "",
          OPTIONS: [
            {
              componentType: "Input",
              label: "Other",
              id: "other",
              name: "other",
              type: "text",
            },
          ],
        },
        {
          QUESTION_TEXT: "What is the date of formation",
          OPTIONS: [
            {
              componentType: "Input",
              label: "Date of Formation",
              id: "formationDate",
              name: "formationDate",
              type: "date",
            },
          ],
          VALIDATION: [required],
        },
      ],
    },
  ]

export const SUB_QUESTION_FED_TAX: SubQuestions<Options>[] = [
  {
    EXPECTED_PARENT_VALUE: [Options.YES],
    QUESTIONS: [
      {
        QUESTION_TEXT: "Please enter your Employer Identication number",
        OPTIONS: [
          {
            componentType: "Input",
            label: "Employer Identication Number",
            id: "ein",
            name: "ein",
            type: "text",
            helperText: "e.g. 12-3456789",
            surfly_private: "true",
            VALIDATION: [required, mustBeEIN],
            format: formatEIN,
          },
        ],
      },
      {
        QUESTION_TEXT: "Reenter your Employer Identication Number",
        OPTIONS: [
          {
            componentType: "Input",
            label: "Reenter Employer Identication Number",
            id: "confirmEIN",
            name: "confirmEIN",
            type: "text",
            surfly_private: "true",
            format: formatEIN,
          },
        ],
      },
    ],
  },
  {
    EXPECTED_PARENT_VALUE: [Options.NO],
    QUESTIONS: [
      {
        QUESTION_TEXT:
          "Please enter the business owner's Social Security Number (SSN)",
        OPTIONS: [
          {
            componentType: "Input",
            label: "Business Owner SSN",
            id: "ssn",
            name: "ssn",
            type: "text",
            helperText: "e.g. 123-45-6789",
            surfly_private: "true",
            VALIDATION: [required, mustBeSSN],
            format: formatSSN,
          },
        ],
      },
      {
        QUESTION_TEXT:
          "Reenter the business owner's Social Security Number (SSN)",
        OPTIONS: [
          {
            componentType: "Input",
            label: "Business Owner SSN",
            id: "confirmSSN",
            name: "confirmSSN",
            type: "text",
            surfly_private: "true",
            format: formatSSN,
          },
        ],
      },
    ],
  },
]

export const UPDATE_SUB_QUESTIONS_FED_TAX: SubQuestions<Options>[] = [
  {
    EXPECTED_PARENT_VALUE: [Options.YES],
    QUESTIONS: [
      {
        QUESTION_TEXT:
          "Please enter your updated Employer Identification Number, if applicable.",
        OPTIONS: [
          {
            componentType: "Input",
            label: "Employer Identication Number",
            id: "ein",
            name: "ein",
            type: "text",
            helperText: "e.g. 12-3456789",
            surfly_private: "true",
            VALIDATION: [required, mustBeEIN],
            format: formatEIN,
          },
        ],
      },
      {
        QUESTION_TEXT:
          "Reenter your updated Employer Identification Number, if applicable.",
        OPTIONS: [
          {
            componentType: "Input",
            label: "Reenter Employer Identication Number",
            id: "confirmEIN",
            name: "confirmEIN",
            type: "text",
            surfly_private: "true",
            format: formatEIN,
          },
        ],
      },
    ],
  },
  {
    EXPECTED_PARENT_VALUE: [Options.NO],
    QUESTIONS: [
      {
        QUESTION_TEXT:
          "Please enter the business owner's Social Security Number (SSN)",
        OPTIONS: [
          {
            componentType: "Input",
            label: "Business Owner SSN",
            id: "ssn",
            name: "ssn",
            type: "text",
            helperText: "e.g. 123-45-6789",
            surfly_private: "true",
            VALIDATION: [required, mustBeSSN],
            format: formatSSN,
          },
        ],
      },
      {
        QUESTION_TEXT:
          "Reenter the business owner's Social Security Number (SSN)",
        OPTIONS: [
          {
            componentType: "Input",
            label: "Business Owner SSN",
            id: "confirmSSN",
            name: "confirmSSN",
            type: "text",
            surfly_private: "true",
            format: formatSSN,
          },
        ],
      },
    ],
  },
]

export const BUISNESS_INFORMATION_QUESTIONS: QuestionsInterface<
  LegalBusinessForm | Options
>[] = [
  {
    QUESTION_TEXT: "What is your contact information? ",
    QUESTION_TEXT_HELPER:
      "(We will only use this contact information for communication about your account.)",
    OPTIONS: [
      {
        componentType: "Input",
        label: "First Name",
        name: "firstName",
        id: "firstName",
        type: "text",
        VALIDATION: [required, maxLength('firstName', 50)],
      },
      {
        componentType: "Input",
        label: "Last Name",
        name: "lastName",
        id: "lastName",
        type: "text",
        VALIDATION: [required, maxLength('lastName', 50)],
      },
      {
        componentType: "Input",
        label: "Phone Number",
        name: "phoneNumber",
        id: "phoneNumber",
        type: "text",
        VALIDATION: [required, mustBePhone],
        format: formatPhone,
        dataTestId: "phoneNumber",
      },
      {
        componentType: "Input",
        label: "Email Address",
        name: "email",
        id: "email",
        type: "text",
        VALIDATION: [required, mustBeEmail],
        dataTestId: "email",
      },
    ],
    id: BusinessInfoQuestions.ContactInfo,
  },
  {
    QUESTION_TEXT: "What is your doing business as (DBA) name?",
    id: BusinessInfoQuestions.BusinessName,
    OPTIONS: [
      {
        componentType: "Input",
        label: "Business Name",
        name: "businessName",
        id: "businessName",
        type: "text",
        dataTestId: "businessname",
        helperText: "Maximum 40 charecters",
      },
    ],
    VALIDATION: [required, maxLength('businessName', 40)],
  },
  {
    QUESTION_TEXT: "Corporation or business network you’re affiliated with",
    id: BusinessInfoQuestions.CorporateGroupName,
    OPTIONS: [
      {
        componentType: "Input",
        label: "Corporate Group Name",
        name: "corporateGroupName",
        id: "corporateGroupName",
        type: "text",
      },
    ],
    QUESTION_TEXT_HELPER: " (If Applicable)",
    VALIDATION: [mustBeValidName('corporateGroupName')],
  },
  {
    QUESTION_TEXT: "When was your business established?",
    id: BusinessInfoQuestions.BusinessEstablishmentDate,
    OPTIONS: [
      {
        componentType: "Input",
        label: "Business Date",
        name: "businessDate",
        id: "businessDate",
        type: "date",
      },
    ],
    VALIDATION: [required],
  },
  {
    QUESTION_TEXT: "How do we contact your business?",
    id: BusinessInfoQuestions.BuisnessContact,
    OPTIONS: [
      {
        componentType: "Input",
        label: "Business Phone Number",
        name: "businessPhoneNum",
        id: "businessPhoneNum",
        type: "text",
        VALIDATION: [required, mustBePhone],
        format: formatPhone,
      },
      {
        componentType: "Input",
        label: "Business Email Address",
        name: "businessEmail",
        id: "businessEmail",
        type: "text",
        VALIDATION: [required, mustBeEmail],
      },
    ],
  },

  {
    QUESTION_TEXT: "Describe your physical business location",
    id: BusinessInfoQuestions.PhysicalLocation,
    OPTIONS: [
      {
        componentType: "Radio",
        label: "Single Business Building",
        name: "physicalLocation",
        id: "singleBusinessBuilding",
        type: "radio",
        value: PhysicalLocation.SingleBusinessBuilding,
      },

      {
        componentType: "Radio",
        label: "Inside Mall or Another Business",
        name: "physicalLocation",
        id: "insideMallorAnotherBusiness",
        type: "radio",
        value: PhysicalLocation.InsideMallorAnotherBusiness,
      },
      {
        componentType: "Radio",
        label: "Located on Personal residence",
        name: "physicalLocation",
        id: "personalResidence",
        type: "radio",
        value: PhysicalLocation.PersonalResidence,
      },

      {
        componentType: "Radio",
        label: "Mobile Vet",
        name: "physicalLocation",
        id: "mobileVet",
        type: "radio",
        value: PhysicalLocation.MobileVet,
      },
    ],
    VALIDATION: [required],
  },
  {
    QUESTION_TEXT: "What is the legal form of the business?",
    id: BusinessInfoQuestions.LegalBusinessForm,
    OPTIONS: [
      {
        componentType: "Radio",
        label: "Incorporated",
        name: "legalBusinessform",
        id: "incorporated",
        type: "radio",
        value: LegalBusinessForm.Incorporated,
      },

      {
        componentType: "Radio",
        label: "Limited Liability Company (LLC)",
        name: "legalBusinessform",
        id: "llc",
        type: "radio",
        value: LegalBusinessForm.LLC,
      },
      {
        componentType: "Radio",
        label: "Sole Proprietorship",
        name: "legalBusinessform",
        id: "sole-proprietorship",
        type: "radio",
        value: LegalBusinessForm.SoleProprietorship,
      },

      {
        componentType: "Radio",
        label: "Partnership",
        name: "legalBusinessform",
        id: "partnership",
        type: "radio",
        value: LegalBusinessForm.Partnership,
      },
      {
        componentType: "Radio",
        label: "Limited Liability Partnership (LLP)",
        name: "legalBusinessform",
        id: "llp",
        type: "radio",
        value: LegalBusinessForm.LLP,
      },
      {
        componentType: "Radio",
        label: "Other",
        name: "legalBusinessform",
        id: "legal-others",
        type: "radio",
        value: LegalBusinessForm.Other,
      },
    ],
    SUB_QUESTIONS: [...SUB_QUESTION_LEGAL_BUSINESS_FORM],
    VALIDATION: [required],
  },
  {
    QUESTION_TEXT:
      "Does the business have a Federal Employer Identification Number?",
    id: BusinessInfoQuestions.BusinessFedTaxIdentificationNumber,
    OPTIONS: [
      {
        componentType: "Radio",
        label: "Yes",
        name: "hasEIN",
        id: "yes",
        type: "radio",
        value: Options.YES,
        dataTestId: "fed-tax-yes",
      },
      {
        componentType: "Radio",
        label: "No",
        name: "hasEIN",
        id: "no",
        value: Options.NO,
        type: "radio",
        dataTestId: "fed-tax-no",
      },
    ],
    SUB_QUESTIONS: [...SUB_QUESTION_FED_TAX],
    VALIDATION: [required],
  },
]

export const TERMS = {
  optionDefault: {
    label:
      "I would like Hill’s to display this location’s retail information on their websites so that consumers can easily find businesses in their area",
    name: "terms",
    id: "terms",
    dataTestId: "terms",
    type: "checkbox",
    defaultValue: true,
    componentType: "Checkbox",
    helperText: "(Optional)",
  },
  option: {
    label:
      "I would like Hill’s to display this location’s retail information on their websites so that consumers can easily find businesses in their area",
    name: "terms",
    id: "terms",
    dataTestId: "terms",
    type: "checkbox",
    componentType: "Checkbox",
    helperText: "(Optional)",
  },
}

export const CORPORATE_GROUP_DISABLED: QuestionsInterface<
  LegalBusinessForm | Options
> = {
  QUESTION_TEXT: "Corporation or business network you’re affiliated with",
  id: BusinessInfoQuestions.CorporateGroupName,
  OPTIONS: [
    {
      componentType: "Input",
      label: "Corporate Group Name",
      name: "corporateGroupName",
      id: "corporateGroupName",
      type: "text",
      disabled: true,
      warningtext:
        "To change your affiliated corporate or business network, please contact Hill’s New Accounts team at 1 (800) 354-4557 (select option 2 and then option 7) or via email at new_accounts_1@hillspet.com.",
    },
  ],
  QUESTION_TEXT_HELPER: " (If Applicable)",
}

export const UPDATE_DBA: QuestionsInterface<LegalBusinessForm | Options> = {
  QUESTION_TEXT: "What is your doing business as (DBA) name?",
  id: BusinessInfoQuestions.BusinessName,
  OPTIONS: [
    {
      componentType: "Input",
      label: "Business Name",
      name: "businessName",
      id: "businessName",
      type: "text",
      dataTestId: "businessname",
      helperText: "Maximum 40 charecters",
    },
  ],
}

export const UPDATE_LEGAL_BUSINESS_FORM: QuestionsInterface<
  LegalBusinessForm | Options
> = {
  QUESTION_TEXT: "What is the updated legal form of the business?",
  id: BusinessInfoQuestions.LegalBusinessForm,
  OPTIONS: [
    {
      componentType: "Radio",
      label: "Incorporated",
      name: "legalBusinessform",
      id: "incorporated",
      type: "radio",
      value: LegalBusinessForm.Incorporated,
    },

    {
      componentType: "Radio",
      label: "Limited Liability Company (LLC)",
      name: "legalBusinessform",
      id: "llc",
      type: "radio",
      value: LegalBusinessForm.LLC,
    },
    {
      componentType: "Radio",
      label: "Sole Proprietorship",
      name: "legalBusinessform",
      id: "sole-proprietorship",
      type: "radio",
      value: LegalBusinessForm.SoleProprietorship,
    },

    {
      componentType: "Radio",
      label: "Partnership",
      name: "legalBusinessform",
      id: "partnership",
      type: "radio",
      value: LegalBusinessForm.Partnership,
    },
    {
      componentType: "Radio",
      label: "Limited Liability Partnership (LLP)",
      name: "legalBusinessform",
      id: "llp",
      type: "radio",
      value: LegalBusinessForm.LLP,
    },
    {
      componentType: "Radio",
      label: "Other",
      name: "legalBusinessform",
      id: "legal-others",
      type: "radio",
      value: LegalBusinessForm.Other,
    },
  ],
  SUB_QUESTIONS: [...UPDATED_SUB_QUESTIONS_LEGAL_BUSINESS_FORM],
  VALIDATION: [required],
}

export const UPDATE_FEDERAL_IDENTIFICATION_NUM: QuestionsInterface<
  LegalBusinessForm | Options
> = {
  QUESTION_TEXT:
    "Does the business have a Federal Employer Identification Number?",
  id: BusinessInfoQuestions.BusinessFedTaxIdentificationNumber,
  OPTIONS: [
    {
      componentType: "Radio",
      label: "Yes",
      name: "hasEIN",
      id: "yes",
      type: "radio",
      value: Options.YES,
      dataTestId: "fed-tax-yes",
    },
    {
      componentType: "Radio",
      label: "No",
      name: "hasEIN",
      id: "no",
      value: Options.NO,
      type: "radio",
      dataTestId: "fed-tax-no",
    },
  ],
  SUB_QUESTIONS: [...UPDATE_SUB_QUESTIONS_FED_TAX],
  VALIDATION: [required],
}
