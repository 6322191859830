import { useEffect, useRef } from 'react';

export const trackFieldAbandonment = (fieldName:string) => {
    const form_id = (window?.location?.hash || 'signup_form').replace('#/', '');
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'form_field_abandonment',
        'form_id': form_id,
        'field_name': fieldName,
        'page_path': window?.location?.href || ''
    });
};

export const trackFieldValidation = (fieldName:string, message:string) => {
    const form_id = (window?.location?.hash || 'signup_form').replace('#/', '');
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'form_field_error',
        'form_id': form_id,
        'field_name': fieldName,
        'error_message': message,
        'page_path': window?.location?.href || ''
    });
};

export const trackFailedSubmision = (form_id:string, message:string) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'form_submission',
        'form_id': form_id,
        'status': 'failure',
        'error_message': message,
        'page_path': window?.location?.href || ''
    });
};

export const trackAccountType = (account_type:string) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'account_type_selected',
        'form_id': 'signup_form',
        'account_type': account_type,
        'page_path': window?.location?.href || ''
    });
};


export const trackSuccessfulAccountCreation = (user_id:string, account_type:string) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'account_creation_success', 
        'form_id': 'new_account_form',
        'user_id': user_id,
        'account_type': account_type,
        'submission_time': new Date(),
        'page_path': window?.location?.href || ''
    });
};

export const trackScrollDepth = (scrollDepthPercentage: number) => {
    scrollDepthPercentage = Number.isNaN(scrollDepthPercentage) ? 100: scrollDepthPercentage;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'scroll_depth',
      'page_path':  window?.location?.href || '',
      'scroll_depth_percentage': scrollDepthPercentage
    });
};

export const ScrollDepthTracker = () => {
    const lastScrollDepth = useRef<number>(0);
  
    useEffect(() => {
      lastScrollDepth.current = 0; // Reset scroll depth tracking when the step changes
      const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const docHeight = Math.max(
          document.body.scrollHeight,
          document.documentElement.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.offsetHeight,
          document.body.clientHeight,
          document.documentElement.clientHeight
        );
        const winHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        const scrollDepth = (scrollTop / (docHeight - winHeight)) * 100;
  
        // Round to nearest 25
        const scrollDepthRounded = Math.ceil(scrollDepth / 25) * 25;
        if (scrollDepthRounded !== lastScrollDepth.current) {
          lastScrollDepth.current = scrollDepthRounded;
          trackScrollDepth(scrollDepthRounded);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    return null;
};

export const trackFormAbandonment = (lastField: string) => {
    const form_id = (window?.location?.hash || 'signup_form').replace('#/', '');
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'form_abandonment',
      'form_id': form_id,
      'last_field': lastField,
      'page_path': window?.location?.href || ''
    });
};

export const FormAbandonmentTracker = () => {
    const lastFieldRef = useRef<string>('');
  
    const handleFieldInteraction = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target && target.name) {
        lastFieldRef.current = target.name;
      }
    };
  
    useEffect(() => {
      const formElements = document.querySelectorAll('input, textarea, select');
      formElements.forEach((element) => {
        element.addEventListener('input', handleFieldInteraction);
      });
  
      const handleBeforeUnload = () => {
        trackFormAbandonment(lastFieldRef.current);
      };
  
      window.addEventListener('beforeunload', handleBeforeUnload);
      window.addEventListener('pagehide', handleBeforeUnload); // Ensure mobile browsers are tracked
  
      return () => {
        formElements.forEach((element) => {
          element.removeEventListener('input', handleFieldInteraction);
        });
        window.removeEventListener('beforeunload', handleBeforeUnload);
        window.removeEventListener('pagehide', handleBeforeUnload);
      };
    }, []);
  
    return null;
};